<template>
  <b-container fluid class="overflow-hidden">
    <b-row class="flex-column flex-md-row min-vh-100 position-relative">
      <b-col
        class="flex-grow-0 flex-md-grow-1 px-5 py-3 d-flex flex-column text-light bg-primary clip-right position-relative"
        :style="`background-image: url(${$images.loginBg});`"
      >
        <Logo favicon />
      </b-col>

      <b-col class="py-5 mx-lg-6" align-self="center">
        <b-container style="max-width: 650px">
          <b-overlay :show="loading">
            <h1>{{ $t("login.title") }}</h1>
            <div class="text-terciary">{{ $t("login.subtitle") }}</div>

            <b-form @submit.prevent="login" class="mt-5">
              <b-form-group
                :label="`${$t('email')}:`"
                label-for="email"
                class="with-icon mb-4"
              >
                <b-icon-envelope />
                <b-form-input
                  id="email"
                  name="email"
                  v-model="email"
                  type="email"
                  required
                />
              </b-form-group>

              <b-form-group
                :label="`${$t('password')}:`"
                label-for="password"
                class="with-icon mb-4"
              >
                <b-icon-lock />
                <b-form-input
                  id="password"
                  name="password"
                  v-model="password"
                  type="password"
                  required
                />
              </b-form-group>

              <b-form-invalid-feedback
                :state="error ? false : null"
                class="mb-3"
              >
                {{ error }}
              </b-form-invalid-feedback>

              <b-form-group>
                <b-link v-b-modal.forgot-password>{{
                  $t("login.forgotpassword.link")
                }}</b-link>
              </b-form-group>

              <b-modal
                @ok.prevent="forgotPassword"
                id="forgot-password"
                :title="$t('login.forgotpassword.title')"
                ok-variant="success"
                :cancel-title="$t('cancel')"
                :ok-title="$t('login.forgotpassword.submit')"
              >
                <b-overlay :show="forgotPasswordLoading">
                  <b-form
                    ref="forgotPasswordForm"
                    @submit.stop.prevent="forgotPasswordSubmit"
                  >
                    <b-form-group
                      :label="`${$t('email')}:`"
                      label-for="forgotPasswordEmail"
                      :description="$t('login.forgotpassword.description')"
                    >
                      <b-form-input
                        id="forgotPasswordEmail"
                        name="forgotPasswordEmail"
                        v-model="forgotPasswordEmail"
                        type="email"
                        required
                      />
                    </b-form-group>

                    <b-button
                      ref="forgotPasswordSubmitButton"
                      class="d-none"
                      type="submit"
                    />
                  </b-form>
                </b-overlay>
              </b-modal>

              <b-button block type="submit" variant="primary" class="mt-5">
                {{ $t("login.submit") }}
              </b-button>
            </b-form>

            <div class="mt-4">
              <b-row class="text-terciary mb-4">
                <b-col><hr /></b-col>
                <b-col cols="auto">{{ $t("not_member") }}</b-col>
                <b-col><hr /></b-col>
              </b-row>

              <b-button block variant="outline-primary" to="/register">
                {{ $t("register.general") }}
              </b-button>
            </div>
          </b-overlay>
        </b-container>
      </b-col>

      <div
        class="d-md-block d-none bg-primary position-absolute rounded-circle"
        style="bottom: 0; left: 0; width: 600px; height: 600px; transform: translate(-25%, 60%); opacity: 0.75;"
      ></div>
      <div
        class="d-md-block d-none position-absolute text-white p-5"
        style="bottom: 0; left: 0;"
      >
        <div class="h4 mb-0">
          {{ $t("login.start_your") }}
        </div>
        <div class="display-3 font-weight-semibold">
          {{ $t("login.journey") }}
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios"
import Logo from "@/components/Logo.vue"
import { BIconEnvelope, BIconLock } from "bootstrap-vue"

export default {
  components: { Logo, BIconEnvelope, BIconLock },
  data() {
    return {
      error: "",
      loading: false,
      email: "",
      password: "",
      forgotPasswordError: "",
      forgotPasswordLoading: false,
      forgotPasswordEmail: "",
    }
  },

  methods: {
    async login() {
      this.error = ""
      this.loading = true

      try {
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        })
        await this.UserIsAdmin()
        if (this.$route.query.url) {
          this.$router.replace(this.$route.query.url)
        } else {
          this.$router.replace({ name: "MyHealth", query: this.$route.query })
        }
      } catch ({ response }) {
        this.error = response
          ? `${response.data.message}: ${this.$t("invalid_login")}`
          : this.$t("invalid_login")
      } finally {
        this.loading = false
      }
    },
    async UserIsAdmin() {
      const response = await axios.get("/user")
      if (response.status === 200) {
        if (response.data.data.user_is_admin === "Y") {
          this.$store.dispatch("UserIsAdmin", true)
        }
      }
    },
    forgotPassword() {
      // Simulate click on the form button
      this.$refs.forgotPasswordSubmitButton.click()
    },
    async forgotPasswordSubmit() {
      this.forgotPasswordError = ""
      this.forgotPasswordLoading = true
      try {
        const noAuth = axios.create()
        noAuth.interceptors.request.use(config => {
          delete config.headers.common.Authorization
          return config
        })
        await noAuth.post("/password/email", {
          email: this.forgotPasswordEmail,
        })
        this.forgotPasswordEmail = ""
        this.$bvModal.hide("forgot-password")
      } catch ({ name, message, response }) {
        this.forgotPasswordError = `${name}: ${message}`
      }
      await new Promise(resolve => setTimeout(resolve, 1000))

      this.forgotPasswordError = ""
      this.forgotPasswordLoading = false
    },
  },
}
</script>
